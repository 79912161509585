
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Champion from '@/components/lol/Champion.vue'


@Options({components: {Champion}})
export default class LolHome extends Vue {

    loading = true
    error = false
    errorDetail = ""
    opChamps: any = {}
    opChampElo = ""
    summonerSearchName = ""
    summonerSearchPlatform = localStorage['lol:platform']
    championSearchName = ""
    championSearchElo = ""
    championSearchQualId: string | null = null
    championSearchQualKey: string | null = null
    championSearchQualRole: string | null = null

    roleOrder = ["top", "jungle", "middle", "bottom", "support"]

    @Watch('summonerSearchPlatform')
    platformChanged (after: string): void {
        this.$stge.loc['lol:platform'] = after
    }

    @Watch('championSearchName')
    championSearchNameChanged (after: string): void {
        this.championSearchQualId = this.$asset.lol.champkeys[after]?.id
        this.championSearchQualKey = this.$asset.lol.champkeys[after]?.key
        if (this.championSearchQualId)
            this.championSearchQualRole = this.$asset.lol.champmainroles[this.championSearchQualId][this.championSearchElo]
    }

    @Watch('championSearchElo')
    championSearchEloChanged (after: string): void {
        this.$stge.loc['lol:championSearchElo'] = after
    }

    created(): void {
        this.championSearchElo = this.$stge.loc['lol:championSearchElo'] || "mid"
        this.opChampElo = this.$stge.loc['lol:opChampElo'] || "mid"
        this.$meta.use({
            title: 'League of Legends | StatHub.gg',
            og: {
                image: this.$cdn.url('lol', ['static', 'game-icon'], 'png'),
                description: 'League of Legends up to date champion builds, statistics, player profiles and leaderboards.'
            }
        })
        this.getOpChamps()
    }

    get profileUrl (): string | null {
        if (!this.summonerSearchName)
            return null
        return `/lol/profiles/${this.summonerSearchPlatform}/${this.summonerSearchName}`
    }

    async getOpChamps (): Promise<void> {
        this.loading = true
        try {
            this.opChamps = await this.$cdn.get('lol', 'analytics/champion-op-summary', 'json')
        } catch (e: any) {
            this.error = true
            this.errorDetail = e.response.data.detail
        }
        this.loading = false
    }

    get championSearchImgUrl (): string | null {
        if (this.championSearchQualId == null)
            return null
        return this.$cdn.url('lol', ['champions', this.championSearchQualId], 'png')
    }

    log (val: string): void {
        console.log(val)
    }

}
