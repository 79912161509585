<template>
    <div class="row pt-3 mx-0 mx-lg-4 mx-xl-5">
        <div class="col-12 col-md-7 mt-0 mt-md-4">
            <h2 class="mt-2 mt-md-4">League of Legends Stats</h2>
            <div class="mt-4 mt-md-5 mx-auto">
                <Input
                    placeholder="Search Summoner + Region"
                    width="50%"
                    v-model="summonerSearchName"
                    :border-radiuses="['20px', 0, 0, '20px']"
                    @keyup.enter="$refs.profileSearchButton.$el.click()"
                ></Input>
                <Select
                    :options="$asset.lol.platforms"
                    v-model="summonerSearchPlatform"
                    :border-radiuses="[0, '20px', '20px', 0]"
                    width='80px'
                    z-index="2"
                ></Select>
                <Button class="ms-2" style="min-width: 60px" ref="profileSearchButton" :to="profileUrl">
                    <Icon :size="16" name="search" />
                </Button>
            </div>
            <div class="mt-2 mt-md-4 mx-auto">
                <Input
                    placeholder="Search Champion + ELO"
                    width="50%"
                    :display-img="championSearchImgUrl"
                    :border-radiuses="['20px', 0, 0, '20px']"
                    v-model="championSearchName"
                    @keyup.enter="$refs.championSearchButton.$el.click()"
                ></Input>
                <Select
                    :options="$asset.lol.elos"
                    v-model="championSearchElo"
                    :border-radiuses="[0, '20px', '20px', 0]"
                    width='80px'
                    z-index="1"
                ></Select>
                <Button
                    class="ms-2"
                    style="min-width: 60px"
                    ref="championSearchButton"
                    :to="`/lol/champions/${championSearchElo}/${championSearchQualKey}/${championSearchQualRole}`"
                    :disabled="!!!championSearchQualId"
                >
                    <Icon :size="16" name="chevron-right" />
                </Button>
            </div>
            <VenatusAd class="mt-3 mt-md-5 mx-3" v-if="$store.state.winWidth > 768" :height="200" />
            <VenatusAd class="mt-3 mt-md-5 mx-3" v-else :height="100" />
        </div>
        <div class="col-12 col-md-5 mt-4 mt-md-0">
            <Loading v-if="loading">LOADING</Loading>
            <Error v-else-if="error">{{errorDetail}}</Error>
            <div v-else class="text-left op-champs-container mt-3 mt-md-4 mx-3 mx-md-5">
                <h3 class="text-truncate text-left mx-1">OP Champions</h3>
                <hr>
                <Tab class="mb-4" :options="$asset.lol.longElos" v-model="opChampElo"></Tab>
                <div v-for="role in roleOrder" :key="'op-champs-' + role">
                    <div class="top-bg mt-2 pt-2 pb-1 px-3 capfirst d-flex justify-content-between align-items-center mx-3">
                        <div style="text-lightergray">{{role}}</div>
                        <div>
                            <Champion
                                v-for="champ in opChamps[opChampElo][role]"
                                :key="'op-' + role + champ.id"
                                :gid="champ.id"
                                :r-size="[30, 35, 40]"
                                class="m-1"
                                :elo="opChampElo"
                                :link="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

.op-champs-container {
    height: 70vh;
    overflow-y: auto;
}

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Champion from '@/components/lol/Champion.vue'


@Options({components: {Champion}})
export default class LolHome extends Vue {

    loading = true
    error = false
    errorDetail = ""
    opChamps: any = {}
    opChampElo = ""
    summonerSearchName = ""
    summonerSearchPlatform = localStorage['lol:platform']
    championSearchName = ""
    championSearchElo = ""
    championSearchQualId: string | null = null
    championSearchQualKey: string | null = null
    championSearchQualRole: string | null = null

    roleOrder = ["top", "jungle", "middle", "bottom", "support"]

    @Watch('summonerSearchPlatform')
    platformChanged (after: string): void {
        this.$stge.loc['lol:platform'] = after
    }

    @Watch('championSearchName')
    championSearchNameChanged (after: string): void {
        this.championSearchQualId = this.$asset.lol.champkeys[after]?.id
        this.championSearchQualKey = this.$asset.lol.champkeys[after]?.key
        if (this.championSearchQualId)
            this.championSearchQualRole = this.$asset.lol.champmainroles[this.championSearchQualId][this.championSearchElo]
    }

    @Watch('championSearchElo')
    championSearchEloChanged (after: string): void {
        this.$stge.loc['lol:championSearchElo'] = after
    }

    created(): void {
        this.championSearchElo = this.$stge.loc['lol:championSearchElo'] || "mid"
        this.opChampElo = this.$stge.loc['lol:opChampElo'] || "mid"
        this.$meta.use({
            title: 'League of Legends | StatHub.gg',
            og: {
                image: this.$cdn.url('lol', ['static', 'game-icon'], 'png'),
                description: 'League of Legends up to date champion builds, statistics, player profiles and leaderboards.'
            }
        })
        this.getOpChamps()
    }

    get profileUrl (): string | null {
        if (!this.summonerSearchName)
            return null
        return `/lol/profiles/${this.summonerSearchPlatform}/${this.summonerSearchName}`
    }

    async getOpChamps (): Promise<void> {
        this.loading = true
        try {
            this.opChamps = await this.$cdn.get('lol', 'analytics/champion-op-summary', 'json')
        } catch (e: any) {
            this.error = true
            this.errorDetail = e.response.data.detail
        }
        this.loading = false
    }

    get championSearchImgUrl (): string | null {
        if (this.championSearchQualId == null)
            return null
        return this.$cdn.url('lol', ['champions', this.championSearchQualId], 'png')
    }

    log (val: string): void {
        console.log(val)
    }

}
</script>
